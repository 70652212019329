import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-admin": () => import("/vercel/path0/middleware/authAdmin.ts"),
  "auth-student": () => import("/vercel/path0/middleware/authStudent.ts"),
  "auth-system-admin": () => import("/vercel/path0/middleware/authSystemAdmin.ts"),
  "auth-teacher": () => import("/vercel/path0/middleware/authTeacher.ts")
}